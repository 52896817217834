<template>
  <div class="section header" :class="{ 'header-scroll': isFixed }">
    <div class="content">
      <div class="title">厦门微财融资担保有限公司</div>
      <ul>
        <li v-for="(item, index) in navList" :key="item.href">
          <a :href="item.href" :class="{ active: index === activeIndex }">{{ item.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isFixed: false,
      navList: [
        {
          href: '/',
          label: '首页'
        },
        {
          href: '/business',
          label: '业务板块'
        },
        {
          href: '/aboutUs',
          label: '关于我们'
        },
      ],
    }
  },
  computed: {
    activeIndex: () => {
      switch (location.pathname) {
        case '/business':
          return 1
          break;
        case '/aboutUs':
          return 2
          break;

        default:
          return 0
          break;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let offsetTop = document.querySelector('.header').offsetTop
      this.isFixed = scrollTop > offsetTop
    }
  }
}
</script>

<style scoped lang="less">
.header {
  height: 86px;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 10;
  transition: all 0.3s;

  &>div {
    height: 100%;
    position: relative;
    margin: 0 auto;
    width: 1080px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .title {
    height: 18px;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 18px;
    letter-spacing: 1px;
  }

  ul {
    float: right;

    li {
      float: left;
      margin: 0 38px 0 20px;

      a {
        position: relative;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        letter-spacing: 1px;

        &::before {
          box-sizing: border-box;
          cursor: pointer;
          content: "";
          width: 28px;
          color: inherit;
          height: 2px;
          background: #ffffff;
          position: absolute;
          bottom: -9px;
          left: 0;
          right: 0;
          -webkit-transform: scaleX(0);
          -ms-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: right center;
          -ms-transform-origin: right center;
          transform-origin: right center;
          -webkit-transition: -webkit-transform 0.4s cubic-bezier(1, 0, 0, 1);
          transition: -webkit-transform 0.4s cubic-bezier(1, 0, 0, 1);
          transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
          transition: transform 0.4s cubic-bezier(1, 0, 0, 1),
            -webkit-transform 0.4s cubic-bezier(1, 0, 0, 1);
        }
      }

      a:hover,
      a.active {
        &::before {
          transform: scaleX(1);
          transform-origin: left center;
          left: 3px;
        }
      }

      &:not(:first-child) {
        a {
          &::before {
            left: 16px !important;
          }
        }
      }
    }
  }
}

.header-scroll {
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.02);

  .line {
    border-right: 1px solid #004bb3 !important;
  }

  .title {
    color: #004bb3 !important;
  }

  ul li a {
    color: #004bb3;

    &::before {
      background: #1468dd !important;
    }
  }
}
</style>
