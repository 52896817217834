<template>
  <div class="rd_footer">
    <div class="footer_content clearfix">
      <div class="f_b">
        <div>
          Copyright 2022  厦门微财融资担保有限公司版权所有
        </div>
        <div style="display: flex;align-items: center;">
          闽ICP备2022015862号-1
          <a target="_blank" href="https://api-m.we.cn/landingPage/page?code=XMRD_License">《电子营业执照》</a>
          <a href="https://xyt.xcc.cn/getpcInfo?sn=1726872622827757568&language=CN&certType=8&url=*.xmwcrd.cn" target="_blank" style="position: relative;display: inline-block;height: 38px;">
            <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1726872622827757568=*.xmwcrd.cn.svg"width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/></a>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog" v-if="dialogShow">
    <div class="mask">
      <div class="dialog-body">
        <div class="dialog-top"></div>
        <div class="dialog-main">
          <div class="content">
          </div>
        </div>
        <div class="close-dialog" @click="dialogShow = false"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
const dialogShow = ref(false)
</script>
<style lang="less" scoped>
.dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(66, 66, 66, 0.4);

  .dialog-body {
    background: #FFF;
    border-radius: 10px;
    position: relative;
    width: 456px;
    height: 380px;

    .dialog-top {
      height: 45px;
      font-size: 18px;
      font-weight: 600;
      color: #2E2E33;
      text-align: center;
      line-height: 24px;
    }

    .content {
      width: 456px;
      height: 312px;
      background: url(@/assets/zz.png) no-repeat;
      background-size: cover;
    }

    .close-dialog {
      position: absolute;
      width: 14px;
      height: 14px;
      background: url(@/assets/dialogClose.png) no-repeat;
      top: 15px;
      right: 15px;
      cursor: pointer;
    }
  }

}

.rd_footer {
  height: 116px;
  background: #1B1D30;
}

.footer_content {
  width: 1080px;
  margin: 0 auto;
  font-size: 12px;
  padding-top: 32px;
}

.f_b {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  line-height: 26px;
}

.f_b a,
.f_b a:hover {
  color: rgba(255, 255, 255, 0.5);
}
</style>
