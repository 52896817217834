import {
  createRouter,
  // createWebHashHistory,
  createWebHistory
} from 'vue-router';

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/home/index.vue'),
    meta: {
      title: '首页'
    }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/pages/business/index.vue'),
    meta: {
      title: '业务版块'
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/pages/aboutUs/index.vue'),
    meta: {
      title: '业务版块'
    }
  }
]

const router = createRouter({
  // hash模式：createWebHashHistory，
  // history模式：createWebHistory
  history: createWebHistory(),
  routes
});

export default router;
